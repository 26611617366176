import { useStyles } from '@archipro-design/aria';
import type { ReactElement } from 'react';
import * as S from './BannerAndLogo.style';

interface HeaderProgressProps {
    progress: number;
    total: number;
    current: number;
}

const BannerAndLogoHeaderProgress = ({
    progress,
    total,
    current,
}: HeaderProgressProps) => {
    const { css } = useStyles();

    const ProgressBar = (
        bar: number,
        current: number,
        progress: number
    ): ReactElement => {
        if (current > bar) {
            progress = 100;
        } else if (current < bar) {
            progress = 0;
        } else if (progress > 100) {
            progress = 100;
        }

        return (
            <div
                key={`bannerImgCarouselProgressBar${bar}`}
                className={`${css(S.MeterStyle)} ${
                    current === bar && progress > 10 ? 'animate' : ''
                }`}
            >
                <div
                    className={css(S.BarStyle)}
                    style={{
                        width: `${progress}%`,
                    }}
                />
            </div>
        );
    };

    return (
        <div className={css(S.BaseStyle)}>
            {[...Array(total).keys()].map((index) =>
                ProgressBar(index, current, progress)
            )}
        </div>
    );
};

export default BannerAndLogoHeaderProgress;
