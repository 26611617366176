import type { StyleRule } from '@archipro-design/aria';
import { carouselPaddleClassName } from '@archipro-design/aria';
import { carouselSlotClassNames } from '@archipro-design/aria';
import { carouselClassName } from '@archipro-design/aria';
import { pxArrayToRem } from '@archipro-design/aria';
import {
    pxToRem,
    buttonClassName,
    SubheaderClassName,
    HeaderClassName,
    avatarClassName,
} from '@archipro-design/aria';
import { svgIconClassName } from '@fluentui/react-icons-northstar';

import { getForegroundTriangles } from '@modules/professionals-category/util/get-foreground-triangles';

export const Container: StyleRule = ({ theme, hasBannerImage }) => ({
    width: `100%`,
    height: pxToRem(506),
    position: 'relative',
    marginBottom: pxToRem(68),

    [theme.screen.laptop]: {
        marginBottom: pxToRem(159),
        width: `100%`,
        height: pxToRem(818),
        position: 'relative',
        background: hasBannerImage
            ? `rgba(255,255,255,0.1)`
            : theme.siteVariables.colors.white['FFF'],
    },

    [theme.screen.clamped]: {
        aspectRatio: '1920 / 1080',
        width: '100%',
    },
});

export const BannerWrap: StyleRule = ({ theme }) => ({
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: `100%`,
    width: '100%',
    [`& .${SubheaderClassName}`]: {
        fontSize: pxToRem(30),
        color: theme.siteVariables.colors.grey[300],
    },
    [`& .${carouselClassName}`]: {
        width: `100%`,
        height: `100%`,
        [`& .${carouselSlotClassNames.paddleContainer}`]: {
            [`& .${carouselPaddleClassName}`]: {
                backgroundColor: 'transparent',
            },
        },

        minWidth: pxToRem(414),
        [theme.screen.laptop]: {
            minWidth: 'unset',
        },
    },

    [`&:after`]: {
        content: `''`,
        background: `rgba(0,0,0, 0.3)`,
        display: 'block',
        width: `100%`,
        height: `100%`,
        zIndex: theme.siteVariables.zIndexes.foreground,

        [theme.screen.laptop]: {
            display: 'none',
        },
    },
});

export const LogoWrap: StyleRule = ({ theme, hasLogo }) => ({
    position: `absolute`,
    transform: `translate(-50%, 0)`,

    top: pxToRem(388),
    left: '50%',
    zIndex: theme.siteVariables.zIndexes.foreground,

    [theme.screen.laptop]: {
        top: `100%`,
        left: pxToRem(974),
        right: 'auto',

        transform: `translate(0, ${pxToRem(-153)})`,
        margin: hasLogo ? 0 : pxArrayToRem([-1, 0, 0, 1]),
        [`& .${avatarClassName}`]: {
            backgroundColor: hasLogo
                ? 'inherit'
                : theme.siteVariables.colors.white['FFF'],
            border: hasLogo
                ? 0
                : `2px solid ${theme.siteVariables.colors.grey[300]}`,
            userSelect: 'none',
            WebkitUserSelect: 'none',
            [`& .${HeaderClassName}`]: {
                color: hasLogo
                    ? 'inherit'
                    : theme.siteVariables.colors.grey[300],
                fontSize: pxToRem(30),
            },
        },
    },

    [theme.screen.clamped]: {
        left: pxToRem(924),
    },

    '& img': {
        minWidth: `88%`,
    },
});

export const HeartButton: StyleRule = ({ theme }) => {
    return {
        marginRight: pxToRem(18),
        marginTop: pxToRem(3),
        width: pxToRem(48),
        height: pxToRem(48),
        borderRadius: pxToRem(51),
        background: theme.siteVariables.colors.white[300],

        '& :hover': {
            background: theme.siteVariables.colors.white[300],
        },

        '& span:first-child': {
            width: pxToRem(24),
            height: pxToRem(24),
        },
    };
};

export const IconWrap: StyleRule = ({ theme }) => ({
    position: `relative`,
    top: pxToRem(15),
    left: 0,
    zIndex: theme.siteVariables.zIndexes.menuItem,
    justifyContent: `space-between`,
    height: pxToRem(70),

    [`& > .${buttonClassName}`]: {
        padding: 0,
        width: pxToRem(54),
        height: pxToRem(54),
    },

    '& div:last-child': {
        [`& > .${buttonClassName}`]: {
            ...HeartButton({ theme }),
        },
    },
});

export const BottomLeftTriangle: StyleRule = ({ theme }) => {
    return getForegroundTriangles(
        'bottomLeft',
        theme.siteVariables.bodyBackground,
        pxArrayToRem([48, 0, 0, 296])
    );
};

export const BottomRightTriangle: StyleRule = ({ theme }) => {
    return getForegroundTriangles(
        'bottomRight',
        theme.siteVariables.bodyBackground,
        pxArrayToRem([0, 0, 31, 120])
    );
};

export const BottomTrianglesProfessionalBanner: StyleRule = ({ theme }) => ({
    bottom: pxToRem(-107 + 64),
    position: 'absolute',
    '& path': {
        fill: theme.siteVariables.bodyBackground,
    },
});

export const BaseStyle: StyleRule = ({ theme }) => ({
    [theme.screen.min.tablet]: {
        right: pxToRem(50),
        bottom: pxToRem(40),
    },
    right: pxToRem(18),
    zIndex: theme.siteVariables.zIndexes.foreground,
    position: 'absolute',
    backgroundColor: 'unset',
    bottom: pxToRem(-32),
    display: 'flex',
    flexDirection: 'row',
});

export const MeterStyle: StyleRule = ({ theme }) => ({
    marginLeft: pxToRem(4),
    backgroundColor: theme.siteVariables.colors.transparency.white['30%'],
    width: pxToRem(24),
    '&.animate > div': {
        transition: 'width 0.5s linear',
        animationFillMode: 'forwards',
    },
    [theme.screen.min.tablet]: {
        width: pxToRem(48),
    },
});

export const BarStyle: StyleRule = ({ theme }) => ({
    backgroundColor: theme.siteVariables.colors.white['FFF'],
    height: pxToRem(2),
    [theme.screen.min.tablet]: {
        width: pxToRem(4),
    },
});

export const PaddleBase: StyleRule = ({ theme }) => ({
    position: 'absolute',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: pxToRem(400),
    bottom: 0,
    height: '100%',
    '> div': {
        width: pxToRem(50),
        height: pxToRem(50),
        marginBottom: pxToRem(100),
    },
    '> div > span > svg': {
        display: 'none',
        color: theme.siteVariables.colors.primitive.white,
        width: pxToRem(50),
        height: pxToRem(50),
    },
    '&:hover svg': {
        display: 'block',
    },

    [`& > .${svgIconClassName}`]: {
        color: theme.siteVariables.colors.primitive.white,
    },
});

export const LeftPaddle: StyleRule = () => ({
    left: 0,
    '> div': {
        marginLeft: pxToRem(40),
    },
});

export const RightPaddle: StyleRule = () => ({
    right: 0,
    '> div': {
        marginRight: pxToRem(40),
        alignSelf: 'flex-end',
    },
});

export const BannerAwardsWrapper: StyleRule = () => ({
    position: 'relative',
});
